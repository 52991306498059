<template>
    <div>
        <card-header title="Edit Hotel" icon="fa-pencil"/>

        <card-body>
            <hotel-form :hotel="hotel"/>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteHotel"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Hotel?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit"><i class="fas fa-check mr-2"></i>Save Hotel</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "@/TIER/components/CardHeader";
    import CardBody from "@/TIER/components/CardBody";
    import CardFooter from "@/TIER/components/CardFooter";

    import HotelForm from "./HotelForm";

    import {client as http} from "@/http_client";

    export default {
        components: {HotelForm, CardFooter, CardBody, CardHeader},
        props: ['card', 'props'],
        data: function () {
            return {
                hotel: {},
                confirmDelete: false,
                dirty: null
            };
        },
        methods: {
            loadHotel: function () {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId + '/hotels/' + this.props.hotelId, {force: true}).then(response => {
                    this.hotel = response.data;
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);
                http.put('/api/trips/' + this.props.tripId + '/hotels/' + this.props.hotelId, this.hotel, {force: true}).then(response => {
                    this.$reloadCard('trip-hotel', {tripId: this.props.tripId, hotelId: this.props.hotelId});
                    this.$reloadCard('trip-hotels', {tripId: this.props.tripId});

                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteHotel() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);

                    http.delete('/api/trips/' + this.props.tripId + '/hotels/' + this.props.hotelId).then(response => {
                        this.$reloadCard('trip-hotels', {tripId: this.hotel.trip_id});
                        this.$reloadCard('trip', {tripId: this.hotel.trip_id});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.hotelId': function () {
                this.dirty = null;
                this.loadHotel();
            },
            'hotel': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this hotel? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadHotel();
        }
    };
</script>

<style scoped>

</style>
